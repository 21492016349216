import styled from "styled-components";
import topProImage from '../testTT.png';
import topProImage1 from '../testTTO.png';

export const Button = styled.button`
    border-radius: 50px;
    background: ${({ primary }) => (primary ? '#83C441' : '#83C441')};
    white-space: nowrap;
    padding: ${({ big }) => (big ? '14px 48px' : '12px 30px')};
    color: ${({ dark }) => (dark ? '#010606' : '#fff')};
    font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    
    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({ primary }) => (primary ? '#fff' : '#01BF71')};
    }


`;


export const ButtonTT = styled.button`
    white-space: nowrap;
    background: none;
    width: 200px;
    height: 200px;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
   margin-top: 200px;    
   
    background-image: url(${topProImage});
    background-size:80%;
    background-repeat: no-repeat;
    background-position: center;
    transform: translateY(-80%);

    
    &:hover {

        background-image: url(${topProImage1});
    }
`;