import styled from "styled-components";
import logovv from '../logoc.png';

export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;
`;

export const GalleryContainer = styled.div`
    height: 1300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #010606;

    @media screen and (max-width: 768px) {
        height: 1300px;
    }
    
    @media screen and (max-width: 480px) {
        height: 1500px;
    }
`;

export const ServicesWrapper = styled.div`
    max-width: 1000px;
    max-height: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 0 50px;

    @media screen and (max-width: 768px) {
        padding: 0 20px;
     
    }

    @media screen and (max-width: 480px) {
          padding: 0 50px;
    }
`;

export const ServicesCard = styled.div`
    background: #fff;   
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    max-height: 2340px;
    padding: 100px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }
`;

export const ServicesIcon = styled.img`
    height: 160px;
    width: 160px;
    margin-bottom: 10px;
`;

export const ServicesH1 = styled.h1`
    font-size: 4rem;
    color: #83C441;
    border-bottom: 5px solid #83C441;
    width: 1000px;
    align-items: center;
    text-align: center;    

    @media screen and (max-width: 480px) {
        font-size: 2rem;
    }
`;

export const ServicesH2 = styled.h2`
    font-size: 1rem;
    margin-bottom: 10px;
`;

export const ServicesP = styled.p`
    font-size: 1rem;
    text-align: center;
`;



export const ImageContainer = styled.div`
    width: 150px;
    height: 150px;
    margin: 10px;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    @media screen and (max-width: 768px) {
        width: 70px;
        height: 70px;
        margin: 1px;
       
    }

    
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ModalWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom:0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.5);
   

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
    
`;

export const ModalImage = styled.img`
    
    max-width: 90%;
    max-height: 80%;

`;

export const CloseButton = styled.button`
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    color: #fff;
    font-size: 2rem;

    @media screen and (max-width: 768px) {
        top: 1250px;

    }

    @media screen and (max-width: 480px) {
        top: 800px;
    }
    
`;

export const NextButton = styled.button`
    position: absolute;
    top: 50%;
    right: 20px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    color: #fff;
    font-size: 2rem;
    transform: translateY(-50%);
`;

export const PrevButton = styled.button`
    position: absolute;
    top: 50%;
    left: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    color: #FFF;
    font-size: 2rem;
    transform: translateY(-80%);

`;
// New Gallery Modal

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ImageNewGallery = styled.img`
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
    position: relative;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }

    @media screen and (max-width: 1024px) {
        width: 100px;
        height: 100px;
    }

    @media screen and (max-width: 778px){
        width: 80px;
        height: 80px;
    }

    @media screen and (max-width: 480px){
        width: 60px;
        height: 60px;
    }

`;



export const CollectionNew = styled.div`
    display: grid;
    position: flex;
    grid-template-columns: repeat(8, 1fr);
    grid-auto-rows: 150px;   
    padding: 40px;
    
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: #5E5E5E;
    background-image: url(${logovv});
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    background-size: 100%;
    background-position: center;
    background-attachment: scroll;

    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(6, 1fr);
        padding: 20px;
        grid-auto-rows: 120px;
        padding-left: 50px; 
    }

    @media screen and (max-width: 778px) {
        grid-template-columns: repeat(5, 1fr);
        grid-auto-rows: 120px;
        padding: 20px;
        padding-top: 50px;
        padding-left: 40px;
        
    }

    @media screen and (max-width: 480px) {
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: 80px;
        padding-top: 30px; 
    }

    `;

export const PhotoCount = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    color: #000;
    font-size: 2rem;
    font-weight: 600;
`;

export const XButton = styled.button`
    border: none;
    cursor: pointer;
    outline: none;
    color: #000;
    font-size: 0.5rem;
    width: 10px;
    height: 10px;
    

`;
