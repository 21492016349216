import React from 'react'
import { animateScroll as scroll } from 'react-scroll'
import {
    FooterContainer,
    FooterWrap,
    FooterLinksContainer,
    FooterLinksWrapper,
    FooterLinkItems,
    FooterLinkTitle,
    FooterLink,
    SocialMedia,
    SocialMediaWrap,
    SocialLogo,
    WebsiteRights,
    SocialIcons,
} from './FooterElements'
import { FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa';

const Footer = () => {

    const toggleHome = () => {
        scroll.scrollToTop();
    }

  return (
    <FooterContainer>
        <FooterWrap>
            <FooterLinksContainer>
                <FooterLinksWrapper>
                    <FooterLinkItems>
                        <FooterLinkTitle>About Us</FooterLinkTitle>
                        <FooterLink to="/signin">Careers</FooterLink>
                        <FooterLink to="/signin">Investors</FooterLink>
                        <FooterLink to="/signin">Terms of Service</FooterLink>
                    </FooterLinkItems>
                    <FooterLinkItems>
                        <FooterLinkTitle>Contact Us</FooterLinkTitle>
                        <FooterLink to="https://www.google.com/maps/place/474+Forest+St+%2311,+Kearny,+NJ+07032,+USA/@40.7691955,-74.1423138,17z/data=!3m1!4b1!4m5!3m4!1s0x89c25430ef5a8835:0x22c56fffd9ac7b7!8m2!3d40.7691915!4d-74.1397389?entry=ttu"><FaMapMarkerAlt />&ensp;474 Forest st, Unit 11 Kearny NJ 07032</FooterLink>
                        <FooterLink to="/signin"><FaPhone />&ensp;856-278-6073</FooterLink>
                        <FooterLink to="/signin"><FaEnvelope />&ensp;powerup.egs@gmail.com</FooterLink>
                    </FooterLinkItems>
                </FooterLinksWrapper>
                <FooterLinksWrapper>
                    <FooterLinkItems>
                        <FooterLinkTitle>Social Media</FooterLinkTitle>
                        <FooterLink to="https://www.instagram.com/powerup.egs/">Instagram</FooterLink>
                        <FooterLink to="https://www.thumbtack.com/nj/kearny/electrical-repairs/power-up-general-solution/service/419722667307835398?utm_medium=web&utm_source=txt&surface=sp">Thumbtack</FooterLink>
                    </FooterLinkItems>
                </FooterLinksWrapper>
            </FooterLinksContainer>
            <SocialMedia>
                <SocialMediaWrap>
                    <SocialLogo to='/' onClick={toggleHome}>
                        Powerup
                    </SocialLogo>
                    <WebsiteRights>Powerup © {new Date().getFullYear()} All rights reserved.</WebsiteRights><SocialIcons>

                    </SocialIcons>
                    
                </SocialMediaWrap>
            </SocialMedia>
        </FooterWrap>
    </FooterContainer>
  )
}

export default Footer